var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"text-left",attrs:{"cols":"12","lg":"12","md":"12","sd":"12"}},[_c('v-card',{staticClass:"card",attrs:{"elevation":"0","py-0":""}},[_c('h2',{staticClass:"purple_subtitle ml-3 pt-4"},[_vm._v("Saisie libre")]),_c('div',{staticClass:"line ml-3"}),_c('validation-observer',{ref:"form"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.validate('submit', _vm.newCollectible)}}},[_c('v-row',{staticClass:"d-flex flex-row",staticStyle:{"width":"100%"}},[_c('v-col',{attrs:{"cols":"5","lg":"5","md":"5","sm":"6"}},[_c('ValidationProvider',{attrs:{"name":"libelle","rules":"required"}},[_c('v-text-field',{staticClass:"text-field ml-3 ",attrs:{"solo":"","dense":"","flat":"","placeholder":"Libellé *"},model:{value:(_vm.newCollectible.libelle),callback:function ($$v) {_vm.$set(_vm.newCollectible, "libelle", $$v)},expression:"newCollectible.libelle"}})],1)],1),_c('v-col',{attrs:{"cols":"2","lg":"2","md":"2","sm":"3"}},[_c('ValidationProvider',{attrs:{"name":"cubage","rules":{
                  required: true,
                  min_value: 0.01,
                  regex: /^\d{1,10}([.,]\d{1,4})?$/,
                }}},[_c('v-text-field',{staticClass:"volume ml-0 ",attrs:{"solo":"","dense":"","flat":"","placeholder":"Cubage *"},model:{value:(_vm.newCollectible.volume),callback:function ($$v) {_vm.$set(_vm.newCollectible, "volume", $$v)},expression:"newCollectible.volume"}})],1)],1),_c('v-col',{attrs:{"cols":"2","lg":"2","md":"2","sm":"3"}},[_c('ValidationProvider',{attrs:{"name":"poids","rules":{
                  required: true,
                  min_value: 0.001,
                  regex: /^\d{1,10}([.,]\d{1,4})?$/,
                }}},[_c('v-text-field',{staticClass:"volume ml-0",attrs:{"solo":"","dense":"","flat":"","placeholder":"Poids *"},model:{value:(_vm.newCollectible.poids),callback:function ($$v) {_vm.$set(_vm.newCollectible, "poids", $$v)},expression:"newCollectible.poids"}})],1)],1),_c('v-col',{staticClass:"ml-sm-3 ml-md-0 ",attrs:{"cols":"3","lg":"3","md":"3","sm":"12"}},[_c('button',{staticClass:"addProduct",on:{"click":function($event){return _vm.collectibles.push(_vm.newCollectible)}}},[_vm._v(" AJOUTER ")])])],1)],1)]),_c('div',{attrs:{"id":"snack"}},_vm._l((_vm.errors),function(error){return _c('v-snackbar',{key:error,attrs:{"timeout":_vm.timeout,"right":"","rounded":"pill","color":"red darken-2","elevation":"24"},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(error)+" ")])}),1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }