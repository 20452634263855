<template>
  <v-container fluid class="">
    <v-row no-gutters class="">
      <v-col offset-xl="1" xl="7" class="text-left mt-6">
        <div class="font_1">
          {{ client.civilite }}
          {{ client.prenom.charAt(0).toUpperCase() + client.prenom.slice(1) }}
          {{ client.nom.toUpperCase() }} - {{ client.Marche.marche }} -
          {{ client && client.Marche && client.Marche.Secteurs && client.Marche.Secteurs.length >0  && client.Marche ? client.Marche.Secteurs[0].nom_secteur:"" }}
        </div>
      
      </v-col>
    </v-row>

    <v-row class="">
      <v-col offset-xl="1" xl="7" class="text-left">
        <!-- <v-row class="d-flex flex-nowrap">
          <v-col cols="12" lg="12" sd="12"> -->
        <div class="purple-bar">
          <router-link
            :to="{
              name: 'client',
              params: { id_client: $route.params.id_client },
            }"
            style="text-decoration: none"
          >
            <v-icon left large dark> fas fa-angle-left </v-icon>
            <span class="link"> Retour à la fiche client </span>
          </router-link>
        </div>
        <categories :vol=vol />
        <searchProduct />
        <addNewProduct />
      </v-col>

      <v-col cols="4" xl="3" class="">
        <panel @change_vol="changeVol($event)" />
      </v-col>
    </v-row>
    <!-- </v-col>
    </v-row> -->
  </v-container>
</template>

<script>
import api from "@/api/clients";
import categories from "@/components/newCollecte/categories";
import searchProduct from "@/components/newCollecte/searchProduct";
import addNewProduct from "@/components/newCollecte/addNewProduct";
import panel from "@/components/newCollecte/panel";

export default {
  name: "Categories",
  components: {
    categories,
    searchProduct,
    addNewProduct,
    panel,
  },

  data() {
    return {
      client: {
        civilite: "",
        prenom: "",
        nom: "",
        Marche: {
          marche: "",
        },
      },
      vol:0
    };
  },
  created() {
    this.getInfosClient(this.$route.params.id_client);
  },
  methods: {
    getInfosClient(id) {
      api.getClientInfos(id).then((res) => {
        this.client = res.data.client;
      });
    },
    changeVol(vol){
      console.log(vol,'change vol')
      this.vol= vol
    }
  },
  mounted() {},
};
</script>

<style lang="css" scoped>
.font_1 {
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  font-size: 1.71428571rem;
  margin-bottom: -10px;
}

.font_1::first-letter {
  text-transform: capitalize;
}

.purple-bar {
  background-color: #af2c70;
  color: white;
  border-radius: 0px 15px 0px 0px;
  width: 100%;
  padding-left: 1vw;
  padding-top: 3px;
  padding-bottom: 3px;
}

.v-application a {
  color: white;
  text-decoration: none;
}
</style>
